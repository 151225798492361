import favicon from '../static/favicon.ico';
import logo from '../static/images/logo.png';
import hero from '../static/images/hero.jpg';
import heroMobile from '../static/images/hero-mobile.jpg';

// Fountain
import fountainHero from '../static/images/fountain/hero.jpg';
import fountainHeroMobile from '../static/images/fountain/hero-mobile.jpg';
// Fountain products
import blackcherryDrink from '../static/images/fountain/products/blackcherry.jpg';
import cherrylimeadeDrink from '../static/images/fountain/products/cherrylimeade.jpg';
import colaDrink from '../static/images/fountain/products/cola.jpg';
import creamsodaDrink from '../static/images/fountain/products/creamsoda.jpg';
import dietcolaDrink from '../static/images/fountain/products/dietcola.jpg';
import gingerbeerDrink from '../static/images/fountain/products/gingerbeer.jpg';
import orangecreamsodaDrink from '../static/images/fountain/products/orangecreamsoda.jpg';
import rootbeerDrink from '../static/images/fountain/products/rootbeer.jpg';
// Fountain nutrition
import blackcherryNutrition from '../static/images/fountain/nutrition/blackcherry.jpg';
import cherrylimeadeNutrition from '../static/images/fountain/nutrition/cherrylimeade.jpg';
import colaNutrition from '../static/images/fountain/nutrition/cola.jpg';
import creamsodaNutrition from '../static/images/fountain/nutrition/creamsoda.jpg';
import dietcolaNutrition from '../static/images/fountain/nutrition/dietcola.jpg';
import gingerbeerNutrition from '../static/images/fountain/nutrition/gingerbeer.jpg';
import orangecreamsodaNutrition from '../static/images/fountain/nutrition/orangecreamsoda.jpg';
import rootbeerNutrition from '../static/images/fountain/nutrition/rootbeer.jpg';

const site = {
  head: {
    metaTitle: 'Stewart\'s',
    metaDescription: 'Enjoy the world\'s best-tasting old-fashioned root beer.',
    ogTitle: 'Stewart\'s',
    ogDescription: 'Enjoy the world\'s best-tasting old-fashioned root beer.',
    ogSiteName: 'Stewart\'s',
    favicon: favicon
  },
  logo: logo,
  logoAltText: 'Stewart\'s',
  heroImage: hero,
  heroImageMobile: heroMobile,
  blurb: {
    title: 'Stewart\'s',
    navTitle: 'Stewart\'s',
    text: 'In 1924, Frank Stewart set out to develop the world\'s best-tasting root beer, which he intended to sell to supplement his income as a school teacher. With secret recipes in hand, he soon opened his first Stewart\'s Drive-In, and an empire was born.'
  },
  products: {
    title: 'Products',
    navTitle: 'Products',
  },
  legal: 'Stewart\'s is a registered trademark of Dr Pepper/Seven Up, Inc. ©2019 Dr Pepper Snapple Group.',

  fountain: {
    heroImage: fountainHero,
    heroImageMobile: fountainHeroMobile,
    blurb: {
      title: 'Stewart\'s Fountain',
      text: 'Master the art of the perfect pour with our Stewart\'s premium fountain craft sodas.  Made with cane sugar and natural flavors, our unique sodas deliver a craft experience that\'s hard to forget.'
    },
    products: {
      title: 'Fountain Products',
      drinks: [
        {
          title: 'Stewart\'s Root Beer',
          image: {
            src: rootbeerDrink,
            alt: 'Stewart\'s Root Beer Fountain Drink'
          },
          label: {
            src: rootbeerNutrition,
            alt: 'Stewart\'s Root Beer Nutritional Info'
          }
        },
        {
          title: 'Stewart\'s Orange Cream Soda',
          image: {
            src: orangecreamsodaDrink,
            alt: 'Stewart\'s Orange Cream Soda Fountain Drink'
          },
          label: {
            src: orangecreamsodaNutrition,
            alt: 'Stewart\'s Orange Cream Soda Nutritional Info'
          }
        },
        {
          title: 'Stewart\'s Black Cherry',
          image: {
            src: blackcherryDrink,
            alt: 'Stewart\'s Black Cherry Fountain Drink'
          },
          label: {
            src: blackcherryNutrition,
            alt: 'Stewart\'s Black Cherry Nutritional Info'
          }
        },
        {
          title: 'Stewart\'s Cream Soda',
          image: {
            src: creamsodaDrink,
            alt: 'Stewart\'s Cream Soda Fountain Drink'
          },
          label: {
            src: creamsodaNutrition,
            alt: 'Stewart\'s Cream Soda Nutritional Info'
          }
        },
        {
          title: 'Stewart\'s Cherry Limeade',
          image: {
            src: cherrylimeadeDrink,
            alt: 'Stewart\'s Cherry Limeade Fountain Drink'
          },
          label: {
            src: cherrylimeadeNutrition,
            alt: 'Stewart\'s Cherry Limeade Nutritional Info'
          }
        },
        {
          title: 'Stewart\'s Ginger Beer',
          image: {
            src: gingerbeerDrink,
            alt: 'Stewart\'s Ginger Beer Fountain Drink'
          },
          label: {
            src: gingerbeerNutrition,
            alt: 'Stewart\'s Ginger Beer Nutritional Info'
          }
        },
        {
          title: 'Stewart\'s Cola',
          image: {
            src: colaDrink,
            alt: 'Stewart\'s Cola Fountain Drink'
          },
          label: {
            src: colaNutrition,
            alt: 'Stewart\'s Cola Nutritional Info'
          }
        },
        {
          title: 'Stewart\'s Diet Cola',
          image: {
            src: dietcolaDrink,
            alt: 'Stewart\'s Diet Cola Fountain Drink'
          },
          label: {
            src: dietcolaNutrition,
            alt: 'Stewart\'s Diet Cola Nutritional Info'
          }
        },
      ]
    }
  }
};

const tealium = {
  enabled: true,
  urls: {
    utag: '//tags.tiqcdn.com/utag/dpsg/carbonate-beta/prod/utag.js',
    utagSync: '//tags.tiqcdn.com/utag/dpsg/carbonate-beta/prod/utag.sync.js',
  },
};

const typekit = {
  enabled: true,
  id: 'pva7jsu',
};

const facebook = {
  appId: '',
  username: 'keurigdrpepper',
};

const instagram = {
  username: 'keurigdrpepper',
};

const youtube = {
  username: 'keurigdrpepper',
};

const pinterest = {
  username: 'keurigdrpepper',
};

const twitter = {
  username: 'keurigdrpepper',
};

const env = {
  dev: {
    rootUrl: `${site.brand}.test`,
  },
  qc: {
    rootUrl: `${site.brand}.clickhereqc.com`,
  },
  uat: {
    rootUrl: `${site.brand}.clickhereuat.com`,
  },
  production: {
    rootUrl: `${site.brand}.com`,
  },
};

const config = {
  env,
  facebook,
  instagram,
  youtube,
  pinterest,
  twitter,
  site,
  tealium,
  typekit,
};

export default config;